<template>
  <el-dialog v-model="dialogVisible" title="條件搜尋" width="650px">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      hide-required-asterisk
      label-position="top"
      class="search-form"
    >
      <el-form-item class="form-item" v-if="is_headquarters == 'true'">
        <template #label>
          <span class="label">門市</span>
        </template>
        <el-cascader
          v-model="ruleForm.shop"
          :options="getStoreList"
          :show-all-levels="false"
          placeholder="請選擇或輸入門市名稱、代碼"
          style="width: 100%"
          clearable
          filterable
          :props="{ emitPath: false }"
        />
      </el-form-item>
      <el-form-item
        class="form-item"
        prop="date"
        :rules="[
          {
            required: true,
            message: '請選擇交易日期',
            trigger: 'blur',
          },
        ]"
      >
        <template #label>
          <span class="label">交易日期</span>
        </template>
        <el-date-picker
          v-model="ruleForm.date"
          type="daterange"
          unlink-panels
          range-separator="－"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          :shortcuts="shortcuts"
          format="YYYY/MM/DD"
          value-format="YYYY/MM/DD"
          :disabled-date="disabledDate"
          :default-value="timeDefaultShow"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button
          type="primary"
          @click="handleSearch(ruleFormRef)"
          :disabled="!checkSubmit()"
          style="margin-left: 25px"
        >
          搜尋
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { inject, ref } from 'vue';
import { useGetters, useMutations } from '@/utils/mapStore'; // useActions
import { useRouter } from 'vue-router';
import { formatDateTime } from '@/utils/datetime';

export default {
  name: 'analysis-dialog',
  setup() {
    const is_headquarters = sessionStorage.getItem('is_headquarters');
    const dialogVisible = inject('dialogVisible');
    const close = inject('close');
    const { cusProps } = inject('cusProps');
    //設定參數
    const ruleFormRef = ref(null);
    const ruleForm = ref(null);
    //設定初始值
    const { getSearchCondition, getCurrentTab, getCurrentStatus } = useGetters('analysis', [
      'getSearchCondition',
      'getCurrentTab',
      'getCurrentStatus',
    ]);
    if (dialogVisible) {
      // 格式：date:['2023/09/01','2023/09/20'],shop:'id erp_id name'
      ruleForm.value = JSON.parse(
        JSON.stringify({
          date: getSearchCondition.value.datef,
          shop:
            getSearchCondition.value.shop.id +
            ' ' +
            getSearchCondition.value.shop.erp_id +
            ' ' +
            getSearchCondition.value.shop.name,
        })
      );
    }

    //店家選項-----------------------------------------------------
    const getStoreList = useGetters('common', ['getStoreList']);

    //日期相關-----------------------------------------------------
    //日期快捷
    const shortcuts = [
      {
        text: '當天',
        value: () => {
          const day = new Date();
          const end = new Date(day.getTime());
          const start = new Date(day.getTime());
          return [start, end];
        },
      },
      {
        text: '當周',
        value: () => {
          const end = new Date();
          const start = new Date(end.getTime() - 3600 * 1000 * 24 * (end.getDay() === 0 ? 6 : end.getDay() - 1));
          return [start, end];
        },
      },
      {
        text: '當月',
        value: () => {
          const end = new Date();
          const start = new Date(`${end.toLocaleDateString().slice(0, 7)}/01`);
          return [start, end];
        },
      },
    ];
    //預設顯示日期
    const timeDefaultShow = new Date();
    timeDefaultShow.setMonth(new Date().getMonth() - 1);
    //禁用日期
    const disabledDate = (time) => {
      const today = new Date();
      if (time.getTime() > today.getTime()) return true;
    };

    //驗證與檢查-----------------------------------------------------------------
    const checkSubmit = () => {
      let isValid;
      const status = getCurrentStatus.value; //排行榜or趨勢圖
      if (ruleForm.value.shop == null || ruleForm.value.shop == 'undefined undefined') ruleForm.value.shop = '';
      else ruleForm.value.shop = ruleForm.value.shop.trim();

      if (getCurrentTab.value == '1') {
        // 1:門市銷售
        // 未選擇時間 | 若為總公司且為趨勢圖 未選擇門市 | 未改變任一條件
        if (ruleForm.value.date[0] == '' || ruleForm.value.date[1] == '') isValid = false;
        else if (is_headquarters == 'true' && status == 'trend' && ruleForm.value.shop == '') isValid = false;
        else if (
          JSON.stringify(ruleForm.value) ===
          JSON.stringify({
            date: getSearchCondition.value.datef,
            shop:
              getSearchCondition.value.shop.id +
              ' ' +
              getSearchCondition.value.shop.erp_id +
              ' ' +
              getSearchCondition.value.shop.name,
          })
        )
          isValid = false;
        else isValid = true;
      } else {
        // 未選擇時間 | 若為總公司未選擇門市 | 未改變任一條件
        if (ruleForm.value.date[0] == '' || ruleForm.value.date[1] == '') isValid = false;
        else if (is_headquarters == 'true' && ruleForm.value.shop == '') isValid = false;
        else if (
          JSON.stringify(ruleForm.value) ===
          JSON.stringify({
            date: getSearchCondition.value.datef,
            shop:
              getSearchCondition.value.shop.id +
              ' ' +
              getSearchCondition.value.shop.erp_id +
              ' ' +
              getSearchCondition.value.shop.name,
          })
        )
          isValid = false;
        else isValid = true;
      }
      // console.log('isValid---', isValid);
      return isValid;
    };

    //送出
    const router = useRouter();
    const { SET_SEARCH_CONDITION, SET_CPAGE } = useMutations('analysis', ['SET_SEARCH_CONDITION', 'SET_CPAGE']);
    const handleSearch = async (form) => {
      try {
        if (!form) return;
        await form.validate();
        // console.log('ruleForm.value.shop', ruleForm.value.shop);
        let shop = ruleForm.value.shop == null ? ['', '', ''] : ruleForm.value.shop.split(' ');
        let searchData = {
          shop: { id: shop[0], erp_id: shop[1], name: shop[2] },
          date: [
            formatDateTime('YYYYMMDD', ruleForm.value.date[0]),
            formatDateTime('YYYYMMDD', ruleForm.value.date[1]),
          ],
          datef: ruleForm.value.date,
        };
        SET_SEARCH_CONDITION(searchData);
        SET_CPAGE(1);
        router.push('/order/analysis?tab=' + getCurrentTab.value + '&cate=1&page=1');
        close();
      } catch (e) {
        console.log('error', e);
      }
    };
    return {
      is_headquarters,
      dialogVisible,
      close,
      cusProps,
      ruleFormRef,
      ruleForm,
      getStoreList,
      shortcuts,
      timeDefaultShow,
      disabledDate,
      checkSubmit,
      handleSearch,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-form {
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-auto-flow: column;
  grid-column-gap: 25px;
}
.form-item {
  @include basic-form-item;
}
.dialog-footer {
  @include full-button;
}
</style>
